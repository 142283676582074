.ControlsArea {
  margin-top: 20px;
}

.grid-cell {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  margin: 4px;
  cursor: pointer;
}

.icon-card {
  background: #fafafa;
  padding: 4px;
  display: inline-block;
  border: 1px solid #ccc;
  margin: 4px;
}