.edit-accordion-trigger {
  color: blue;
  float: right;
}

.edit-area {
  border: 1px solid #ccc;
  padding: 12px;
}

.edit-task {
  border: 1px solid #ccc;
  padding: 8px;
}

.outer-task-list-container {
  margin-left: 40px;
  position: relative;
}

.task-card {
}

/* a recursive tree of tasks */
.task-tree {
}

ul.task-tree {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.task-tree > li {
  margin-left: 20px;
  background: #fff;
}

/* the first button child */
.task-tree li .add-subtask {
  margin-right: 40px;
}
