.summary-stats span {
  display: inline-block;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  background-color: #f5f5f5;
  color: #555;
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 10px;

}

.source-content-area {
  padding: 20px;
  background-color: #eee;
}

.formatted {
  font-family: "Courier New", Courier, monospace;
  font-size: 12px;
  line-height: 1.5;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.chunk-card {
  margin-bottom: 12px;
  background: #fff;
  padding: 12px;
}

.source-content-area .paragraph {
  margin-bottom: 12px;
  background: #fff;
  padding: 12px;
  white-space: pre-wrap;
  word-wrap: break-word;

}

.source-instruction {
  text-align: center;
  font-size: 1.5em;
  margin-top: 20px;
  background: #f37;
  color: #fff;
  padding: 40px;
}