.edit-accordion-trigger {
  color: blue;
  float: right;
}

.edit-area {
  border: 1px solid #ccc;
  padding: 12px;
}

.character-row  {
  margin-bottom: 12px;
  border: 1px solid #ccc;
  padding: 12px;
}

.character-row img{
  float: left;
  margin-right: 40px;
}

.character-row h3 {
  font-size: 1.5em;
}