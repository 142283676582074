
.background-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(180deg, #ffffff 0%, #1c140d 100%);
}

.background-container svg {
  width: 100%;
  height: 100%;
}

.landing-top-bar {
  padding-top: 40px;
  background-color: #282c34;
}

.landing-title {
  padding-left: 80px;
  font-size: 2rem;
}

.landing-sign-in-block {
  text-align: right;
  float: right;
  color: #000;
  padding-right: 80px;
}

.sign-in-button {
  background: #111;
  color: #fff;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
}

.fade-up {
  opacity: 0;
  transform: translateY(20px);
  animation: fade-slide-in 0.3s 0.7s ease-out forwards;
}

.fade-up-first {
  opacity: 0;
  transform: translateY(20px);
  animation: fade-slide-in 0.3s 0.7s ease-out forwards;
}

.fade-up-second {
  opacity: 0;
  transform: translateY(20px);
  animation: fade-slide-in 0.3s 0.9s ease-out forwards;
}

.fade-up-third {
  opacity: 0;
  transform: translateY(20px);
  animation: fade-slide-in 0.3s 1.2s ease-out forwards;
}

@keyframes slide-in {
  0% {
    transform: translateY(-50%) translateX(100%);
  }
  100% {
    transform: translateY(-50%) translateX(0);
  }
}

/* Content inside the sliding div */
.slide-in-content {
  opacity: 0;
  transform: translateY(20px);
  animation: fade-slide-in 1s 1s ease-out forwards;
}

@keyframes fade-slide-in {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-and-scale-up {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.landing-content {
  background: #fff;
  border: 1px solid #000;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 40px;
}

.landing-content  h1 {
  text-align: left;
}

.landing-content h2 {
  font-size: 3.5rem;
  text-align: center;
}

.bold {
  font-weight: bold;
}

.big {
  font-size: 1.5rem;
}

.landing-content .dark {
  background: #555;
  color: #fff;
  padding: 20px;
  margin: 0;
}

.landing-exposition {
  background: #555;
  color: #fff;
  padding: 40px;
  font-size: 1.5rem;
  margin-top: 80px;
  margin-bottom: 0;
}

.slate {
  background: #eee;
}

.double h2 {
  margin-top: 0;
  padding-top: 40px;
}

.landing-footer {
  background: #555;
  color: #fff;
  padding: 40px;
  font-size: 1.5rem;
  margin-top: 80px;
  margin-bottom: 0;
  text-align: center;
}

.landing-character-cards {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;  /* "thin" scrollbar for Firefox */
  scrollbar-color: transparent transparent;  /* scrollbar color for Firefox */
  white-space: nowrap;
}

.landing-character-cards::-webkit-scrollbar {
  width: 1px;  /* width of the entire scrollbar */
}

.landing-character-cards::-webkit-scrollbar-track {
  background: transparent;  /* color of the tracking area */
}

.landing-character-cards::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);  /* color of the scroll thumb */
  border-radius: 20px;  /* roundness of the scroll thumb */
  border: 3px solid transparent;  /* creates padding around scroll thumb */
}

.landing-character-cards::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);  /* color of the scroll thumb when hovering */
}

.landing-character-cards .character-card {
  padding: 0;
}

.landing-character-cards .character-card img {
  /* make the top right and left corners curved */
  border-radius: 10px 10px 0 0;
}

.landing-exclaimation-box {
  text-align: center;
  font-weight: 800;
  color: #fff;
  font-size: clamp(108px, 15vw, 200px);
  margin: 12px;
  margin-top: 40px;
}

.landing-exclaimation-box .inner-box {
  border: 2px solid #fff;
  display: inline-block;
  width: clamp(220px, 30vw, 400px);
  height: clamp(220px, 30vw, 400px);
}

.landing-exclaimation-box p {
  margin: 0;
  padding: 0;
  padding-top: 28px;
}

.cta-container {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tagline {
  font-size: clamp(20px, 5vw, 40px);
  font-weight: 100;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 8px;
  color: #fff;
}

.lfg-button {
  /* give the background a gradient */
  background: linear-gradient(0deg, #fff, #f8df53);
  color: #000;
  padding: 20px 40px 20px 40px;
  font-weight: bold;
  cursor: pointer; 
  font-size: 3rem;
  border-radius: 12px;
  text-align: center;
  
}