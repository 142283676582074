.template-preview {
  white-space: pre-line;
}

.delete-tag-button {
  margin-left: 0.5em;
  cursor: pointer;
  background: black;
  color: white;
  border-radius: 0.25em;
  padding: 0.25em;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}