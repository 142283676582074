.TopNav {
  background-color: #333;
}

.dark {
  background-color: #333;
}

.credits-container {
  display: inline-block;
}

/* header-project-display-name is going to fade in and out */
.header-project-display-name {
  animation: header-fade-in 0.3s;
  animation-fill-mode: forwards;
  opacity: 0;
}

/* keyframes for header-project-display-name */
@keyframes header-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}