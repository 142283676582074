.character-card {
  display: inline-block;
  padding: 20px;
  margin: 8px;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  transition: all 0.05s ease-in-out;
}
