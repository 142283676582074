body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 15vmin);
  font-weight: 100;
  color: white;
}

.App-link {
  color: #61dafb;
}

h1, h2, h3, h4 {
  font-weight: 200;
}

.padded {
  padding: 24px;
}

.centered {
  text-align: center;
}

/* Style the code block container */
.react-markdown-code-block {
  max-width: 100%; /* Set the maximum width */
  overflow-x: auto; /* Add horizontal scroll when the content overflows */
}

/* Style the actual code within the block */
.react-markdown-code-block code {
  white-space: pre-wrap; /* Preserve the white-space and wrap lines */
}

@keyframes fadeInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated-fade-in-from-left {
  opacity: 0;
  animation-name: fadeInFromLeft;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-delay: var(--delay, 0s);
}

@keyframes fadeInFromRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated-fade-in-from-right {
  opacity: 0;
  animation-name: fadeInFromRight;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-delay: var(--delay, 0s);
}

@keyframes fadeInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated-fade-in-from-bottom {
  opacity: 0;
  animation-name: fadeInFromBottom;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-delay: var(--delay, 0s);
}

@keyframes fadeInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated-fade-in-from-top {
  opacity: 0;
  animation-name: fadeInFromTop;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-delay: var(--delay, 0s);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animated-fade-in {
  background-color: #000;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-delay: var(--delay, 0s);
}