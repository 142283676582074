.ControlsArea {
  margin-top: 20px;
}

.white-bg {
  background-color: #fff;
}

.package-icon-chip {
  display: flex;
  flex-direction: column ;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.package-card {
  width: 256px;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 0 10px #ccc;
  padding: 20px;
  display: inline-block;
  margin: 20px;
}

.package-card canvas {
  margin-left: -20px;
}
