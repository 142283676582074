.Data {
  background-color: #f5f5f5;
  text-align: center;
  min-height: 900px;
}

.hidden {
  display: none;
}

.NavBar {
  background-color: #555555;
  color: #ffffff;
  font-size:large;
  text-align: left;
  min-height: 40px;
  border-bottom: 1px solid #d5d5d5;
}

.NavBar-title {
  padding: 20px;
}

.NavBar-item {
  float: right;
  margin: 12px;
  margin-top: 20px;
}

.UploadVideoArea {
  background-color: #656565;
  color: #fff;
  padding: 12px;
  margin: 12px;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
}

.VideoRecord {
  background-color: #ffffff;
  padding: 12px;
  margin: 12px;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
  cursor: pointer;
  text-align: left;
}

.VideoLabelingArea {
  background-color: #ffffff;
  padding: 12px;
  margin: 12px;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
  text-align: left;
}

.Interval,  .VideoProgressControl {
  background-color: #ffffff;
  margin: 12px 12px 12px 0px;
}

.Interval .Slider {
  margin-top: 12px;
}

.Rating {
  padding-top: 16px;
  text-align: center;
}