.header-container {
  border: 1px solid #555555;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
}

.api-test-area {
  border: 1px solid #555555;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  background: #c8ffdf;
}


.service-test-area {
  border: 1px solid #555555;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  background: #fffcd7;
}

.test-card {
  border: 1px solid #555555;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  background: #ffffff;
  white-space: pre-line;
}

.test-card h3 {
  margin-top: 0;
  font-weight: 800;
  border-bottom: 1px solid #555555;
}