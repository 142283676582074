.summary-stats span {
  display: inline-block;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  background-color: #f5f5f5;
  color: #555;
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 10px;

}

.source-content-area {
  padding: 20px;
  background-color: #dce;
}

.formatted {
  font-family: "Courier New", Courier, monospace;
  font-size: 12px;
  line-height: 1.5;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.chunk-card {
  margin-bottom: 12px;
  background: #fff;
  padding: 12px;
  padding-bottom: 0;
}

.source-content-area .paragraph {
  margin-bottom: 12px;
  background: #fff;
  padding: 12px;
  white-space: pre-wrap;
  word-wrap: break-word;

}

.source-instruction {
  text-align: center;
  font-size: 1.5em;
  margin-top: 20px;
  background: #f37;
  color: #fff;
  padding: 40px;
}

.rule-card {
  margin-top: 12px;
  background: #a77;
  color: #fff;
  padding: 12px;
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.rule-card-buttons-area {
  float: right;
  margin-top: -30px;
  display: inline-block;
}

.rule-card-buttons-area button {
  margin-left: 20px;
  background: #fff;
  border: 1px solid #ddd;
}

.rules-exposition {
  margin-top: 12px;
  margin-bottom: 12px;
  background: #f8f8f8;
  padding: 12px;
  border: 1px solid #eee;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.model-choice-area {
  margin-top: 12px;
  text-align: right;
}

.before-and-after {
  font-weight: 800;
  font-size: 2em;
  padding: 0;
  margin: 0;
  color: #fff;
  position: relative;
  bottom: -13px;
  left: -2px;
}